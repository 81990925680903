import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, inject, input, computed, } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoRootModule } from '@volago/core';
import { ResourceTypeService } from '../resource-type/resource-type.service';
import { Resource, Resources } from '../resource';

@Component({
  selector: 'volago-attraction-resources',
  templateUrl: './resources-list.component.html',
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslocoRootModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourcesListComponent {

  data = input.required<Resources>();

  private resourceTypeService = inject(ResourceTypeService);

  vm = computed<ResourceListItem[]>(() => this.data().items.map(r => ({
    ...r,
    icon: this.resourceTypeService.getResuorceTypeIcon(r.type),
  })));

}

export interface ResourceListItem extends Resource {
  icon: string;
}
