<ng-container *transloco="let t">
  <div class="p-2">

    <div class="mb-2">{{ t('attraction.links')}}</div>

    <div class="grid grid-cols-1 lg:grid-cols-2 gap-2">
      @for (r of vm(); track r) {
      <a [href]="r.url" target="_blank" class="link space-above flex py-2">
        <mat-icon class="mr-4">{{r.icon}}</mat-icon>
        <span>{{r.description}}</span>
      </a>
      }
    </div>
  </div>
</ng-container>