@if (isLogged()) {
<div class="p-2 flex justify-end items-center">

  <div class="mr-4">
    @for (item of [1, 2, 3, 4, 5]; track item) {
      <span [ngClass]="{'filled star': attractionRating().stars >= item}"
        class="material-symbols-outlined text-3xl cursor-pointer mr-1" (click)="toggleStars(item)">
        star
      </span>
    }
  </div>

  <span [ngClass]="{'filled heart': attractionRating().favorite}"
    class="material-symbols-outlined text-3xl cursor-pointer mr-4" (click)="toggleFavorite()">
    favorite
  </span>

  <span [ngClass]="{'filled tour': attractionRating().wantToVisit}"
    class="material-symbols-outlined text-3xl cursor-pointer" (click)="toggleWantToVisit()">
    tour
  </span>

</div>
}