import { Component, OnDestroy, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { MatMenuModule } from '@angular/material/menu';
import { Auth, User, user } from '@angular/fire/auth';
import { Subscription } from 'rxjs';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'volago-user-menu',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatDialogModule, MatMenuModule, RouterModule],
  templateUrl: './user-menu.component.html',
})
export class UserMenuComponent implements OnDestroy {

  private auth: Auth = inject(Auth);
  user$ = user(this.auth);
  userSubscription: Subscription;
  userLoggedIn = false;
  userFirstLetter = '';

  constructor(
    public dialog: MatDialog,
  ) {
    this.userSubscription = this.user$.subscribe((aUser: User | null) => {
      if (aUser) {
        this.userLoggedIn = true;
        this.userFirstLetter = aUser.email?.charAt(0) || '';
      } else {
        this.userLoggedIn = false;
      }
    });
  }

  openLoginDialog(): void {
    this.dialog.open(LoginDialogComponent);
  }

  logout(): void {
    this.auth.signOut();
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }
}
