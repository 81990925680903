<button mat-mini-fab *ngIf="!userLoggedIn" (click)="openLoginDialog()" color="primary"
  aria-label="Icon button with a menu icon">
  <mat-icon>person</mat-icon>
</button>

<button *ngIf="userLoggedIn" mat-mini-fab [matMenuTriggerFor]="menu" color="accent"
  aria-label="Icon button with a menu icon">
  {{ userFirstLetter }}
</button>

<mat-menu #menu="matMenu">
  <button mat-menu-item [routerLink]="['/pl-PL/profile']">Profil</button>
  <button mat-menu-item (click)="logout()">Wyloguj</button>
</mat-menu>