<button mat-mini-fab color="primary" [matMenuTriggerFor]="menu" aria-label="Icon button with flag of current language">
  <img class="rounded-full w-[39px] h-[39px]" [src]="activeFlagUrl">
</button>

<mat-menu #menu="matMenu">

  @for (lang of langs; track lang) {
  <button mat-menu-item (click)="selectLanguage(lang)">
    <span class="flex gap-4">
      <img class="w-6 h-6 rounded-full mat-elevation-z8" src="assets/images/flags/{{lang.id}}.svg" alt="{{lang.name}}">
      {{lang.name}}
    </span>
  </button>
  }

</mat-menu>