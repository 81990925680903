<div *ngIf="attraction$ | async as attraction" class="info-panel mat-app-background">

  <div class="text-right lg:hidden">
    <button mat-icon-button (click)="closed.emit()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <volago-mini-gallery *ngIf="attraction.photos?.length" [photos]="attraction.photos"></volago-mini-gallery>

  <div>

    <div class="p-2 flex flex-col">
      <div class="flex items-center">
        <h1 class="pr-2">
          {{attraction.name}}
          <volago-importance [score]="attraction.importance"></volago-importance>
        </h1>
      </div>
      <span class="mat-caption italic">{{attraction.regions}}</span>
      <span class="mat-caption italic">{{attraction.administrative}}</span>
    </div>

    <volago-tag-list [tags]="attraction.tags"></volago-tag-list>

    <volago-attraction-rating [attractionId]="attraction.id" />

    <div class="p-2 summary mat-body-1 text-justify" [innerHtml]="attraction.summary"></div>
    <hr />

    <volago-contact *ngIf="attraction.contact" [data]="attraction.contact"></volago-contact>

    <hr />

    <volago-attraction-pricing *ngIf="attraction.pricing" [data]="attraction.pricing" />

    <hr />

    @if (attraction.hours) {
    <volago-attraction-hours [data]="attraction.hours" />
    <hr />
    }


    <div class="flex justify-around py-6">
      <volago-score-card *ngIf="attraction.howToGet"
        [data]="{title: 'How to get', icon: 'commute', score: attraction.howToGet.score, description: attraction.howToGet.description}">
      </volago-score-card>
      <volago-score-card *ngIf="attraction.gastronomy"
        [data]="{title: 'Gastronomia', icon: 'local_dining', score: attraction.gastronomy.score, description: attraction.gastronomy.description}">
      </volago-score-card>
      <volago-score-card *ngIf="attraction.parking"
        [data]="{title: 'Parking', icon: 'local_parking', score: attraction.parking.score, description: attraction.parking.description}">
      </volago-score-card>
      <volago-score-card *ngIf="attraction.payment"
        [data]="{title: 'Formy płatności', icon: 'payment', score: attraction.payment.score, description: attraction.payment.description}">
      </volago-score-card>
      <volago-score-card *ngIf="attraction.wc"
        [data]="{title: 'Toalety', icon: 'wc', score: attraction.wc.score, description: attraction.wc.description}">
      </volago-score-card>
      <volago-score-card *ngIf="attraction.children"
        [data]="{title: 'Dzieci', icon: 'child_friendly', score: attraction.children.score, description: attraction.children.description}">
      </volago-score-card>
      <volago-score-card *ngIf="attraction.pets"
        [data]="{title: 'Zwierzęta', icon: 'pets', score: attraction.pets.score, description: attraction.pets.description}">
      </volago-score-card>
      <volago-score-card *ngIf="attraction.accessibility"
        [data]="{title: 'Niepełnosprawni', icon: 'accessible', score: attraction.accessibility.score, description: attraction.accessibility.description}">
      </volago-score-card>
    </div>

    <hr />

    <volago-attraction-resources *ngIf="attraction.resources" [data]="attraction.resources" />

    <hr />

    <div class="p-2 md:text-lg" [innerHtml]="attraction?.description"></div>

  </div>

</div>